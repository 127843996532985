import React from "react"
import { Themed } from "theme-ui"

/**
 * Change the content to add your own bio
 */

export default function Bio() {
  return (
    <>
         This is a test blog, deployed using gatsby and CloudFlare Pages
    </>
  )
}
